import { createTheme } from '@mui/material';

const getTheme = prefersDarkMode =>
  createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      ...(!prefersDarkMode
        ? {
            // palette values for light mode
            primary: { main: 'hsl(354.27, 48%, 43%)' },
            secondary: { main: 'hsl(34.74, 23%, 84%)' },
            error: { main: 'hsl(359.06, 74%, 19%)' },
          }
        : {
            // palette values for dark mode
            primary: { main: 'hsl(354.27, 48%, 27%)' },
            secondary: { main: 'hsl(34.74, 23%, 17%)' },
            error: { main: 'hsl(359.06, 74%, 35%)' },
          }),
    },
    typography: {
      fontFamily: `"Cabin", sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 720,
        md: 900,
        lg: 1280,
        xl: 1600,
      },
    },
    components: {
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            display: 'inline',
            margin: 0,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px grey inset',
            },
          },
        },
      },
    },
  });

export default getTheme;
