import { Box, CircularProgress } from '@mui/material';
import React from 'react';

function Spinner() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress size={100} color="error" />
    </Box>
  );
}

export default Spinner;
