import { useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import getTheme from 'theme';
import router from 'routerConfig';
import Layout from 'components/Layout';
import './styles/global.scss';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode]);

  return (
    <SnackbarProvider maxSnack={2}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <RouterProvider router={router}>
          <Layout />
        </RouterProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
